import React from 'react'
import {css} from '@emotion/core'
import Layout from '../components/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import theme from '../../config/theme'

function FourOFour() {
  return (
    <Layout headerColor={theme.colors.white}>
      <SEO />
      <Container
        noVerticalPadding
        css={css`
          margin-top: 30px;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 40vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <h1>🕹 404 NOT FOUND</h1>
          <p>{`🏔 你现在进入了一个无人的旷野，有点荒凉~~ 有点孤单~~`}</p>
        </div>
      </Container>
    </Layout>
  )
}

export default FourOFour
